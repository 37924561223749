import axios from 'helpers/axios/axios';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import config from 'Config';
import { useQuery } from 'react-query';
import { get, pick } from 'lodash';
import { camelToSnake } from 'helpers/camelToSnake/camelToSnake';
import qs from 'qs';
import { useGetAccessToken } from 'hooks/useGetAcessToken/useGetAccessToken';
import { useEffect } from 'react';
import { REQUEST_HEADERS } from '../../constants';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';

type ContactDetails = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  send_promotions: string;
  identity_id: string;
}

type ContactResult = {
  isLoading: boolean;
  isError: boolean;
  contactDetails: ContactDetails | undefined
}

export const useContactCreation = (): ContactResult => {
  const { isLoading: isAuth0Loading, appState, user } = useAuth0();
  const myobIdentityId = user?.['https://id.myob.com/user_id'].toLowerCase();
  const myobIdEmail = user?.email;
  const { accessToken } = useGetAccessToken();

  const contactsPayloadCamelCase = {
    ...pick(get(
      appState,
      'clientInformation'
    ), 'firstName', 'lastName', 'phoneNumber', 'sendPromotions'),
    email: myobIdEmail,
    identityId: myobIdentityId
  };

  const options = {
    headers: {
      ...REQUEST_HEADERS,
      Authorization: `Bearer ${accessToken}`
    }
  };

  const {
    isLoading: isContactsLoading,
    data: contactData,
    isError,
    isSuccess
  } = useQuery(
    'contacts',
    () =>
      axios.post<ContactDetails>(
        config.API_CONTACTS_ENDPOINT,
        qs.stringify(camelToSnake(contactsPayloadCamelCase)),
        options
      ),
    { enabled: !!appState && !!myobIdentityId && !!accessToken, retry: false }
  );

  const { setContactArchieRowId } = useSessionContext();
  useEffect(() => {
    if (isSuccess) {
      setContactArchieRowId(contactData?.data.id as string);
    }
  }, [isSuccess]);

  const contactDetails = contactData?.data;
  const isLoading = isAuth0Loading || isContactsLoading;
  return {
    isLoading, contactDetails, isError
  };
};
