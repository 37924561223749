import React, { useState } from 'react';
import './EmailInputForm.scss';
import {
  Button, Input, MYOBLogo, PageHead
} from '@myob/myob-widgets';
import { useForm } from 'react-hook-form';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useNinetailedCheckoutEvent } from 'hooks/useNinetailedAnalytics/useNinetailedAnalytics';
import { PromoCodeBox } from 'components/promocodeBox';
import {
  ANALYTICS_ID,
  COLOURS_AS_HEX,
  NINETAILED_ANALYTICS_CONSTANTS,
  REGULAR_EXPRESSIONS
} from '../../constants';
import '../../app/App.scss';
import { getTerms } from '../terms/Terms';
import { useBeginCheckoutEvent } from '../../hooks/GTMAnalytics/useGTMAnalytics';
import { useBeginCheckoutEventGA4 } from '../../hooks/GTM4Analytics/useGTMAnalytics';
import { useCheckoutStarted } from '../../hooks/Analytics/useAnalytics';
import { useSegmentContext } from '../../contexts/segmentProvider/SegmentProvider';
import { usePromoBoxFeatureContext } from '../featureToggle/PromoCodeBoxFeatureToggle';

export const EmailInputForm: React.FC<{
  handleEmailChecked: (emailValue: string) => void
  pagerHeaderTitle: string
  submitButtonText: string
  isError: boolean
}> = ({
  handleEmailChecked, pagerHeaderTitle, submitButtonText, isError
}) => {
  const {
    phoneNumber, promoCode, promotionEndDate
  } = useSessionContext();

  const isSegmentLibraryReady = useSegmentContext();
  const isPromoBoxFeatureEnabled = usePromoBoxFeatureContext();

  const {
    register,
    handleSubmit,
    errors
  } = useForm({ mode: 'onTouched' });

  const [disabled, setDisableValue] = useState(false);
  useBeginCheckoutEvent();
  useCheckoutStarted(isSegmentLibraryReady);
  useBeginCheckoutEventGA4(isSegmentLibraryReady);
  useNinetailedCheckoutEvent(NINETAILED_ANALYTICS_CONSTANTS.BEGIN_CHECKOUT);

  return (
    <div data-testid='email-input-form' className={'feature-box-right'}>
      <div style={{ width: '6.25em', marginBottom: '1em' }}>
        <MYOBLogo />
      </div>
      <PageHead title={pagerHeaderTitle}/>
      <div style={{ width: '20em' }}>
        <Input
          className={'form-input'}
          id={ANALYTICS_ID.INPUT_EMAIL}
          name='email'
          label='Email*'
          reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.EMAIL })}
          disabled={disabled}
          errorMessage={
            errors.email &&
                (errors.email.type === 'required' ? 'Email is required' : 'Email is invalid')}
        />
        {isError &&
          <p style={{ marginTop: '1rem', color: COLOURS_AS_HEX.Berry }}>
            Something went wrong. If the issue persists, contact MYOB support on{' '}
            {phoneNumber.salesPhoneNumber}
          </p>
        }
        { isPromoBoxFeatureEnabled && <PromoCodeBox /> }
        <Button
          id={ANALYTICS_ID.BUTTON_EXISTING_USER_CHECK}
          onClick={handleSubmit((data) => {
            setDisableValue(true);
            handleEmailChecked(data.email);
          })}
          disabled={disabled}
        >
          {submitButtonText}
        </Button>
        {getTerms('terms', promoCode, promotionEndDate)}
      </div>
    </div>
  );
};
