import axios from 'helpers/axios/axios';
import config from 'Config';
import { useQuery } from 'react-query';
import { CreditCardDetail, DirectDebitDetail } from 'components/paymentPage/types';
import qs from 'qs';
import { useGetAccessToken } from 'hooks/useGetAcessToken/useGetAccessToken';
import { PAYMENT_METHOD, REQUEST_HEADERS } from '../../constants';

type PaymentProfile = {
  id: string
  account: {
    id: string
  }
  contact: {
    id: string
  };
  payment_schedule: string;
  payment_method: string;
  credit_card?: {
    name_on_card: string;
    card_token: string;
    last_four_digits: string;
    type: string;
    expiry_month: string;
    expiry_year: string;
  };
  direct_debit?: {
    bank_name: string;
    bsb: string;
    account_number: string;
  }
}

type PaymentProfileCreation = {
  isLoading: boolean;
  isError: boolean;
  paymentProfile?: PaymentProfile;
  isSuccess: boolean;
  refetch: () => void;
}

const buildPayloadCreditCard = (
  creditCardDetail: CreditCardDetail,
  accountId?: string,
  contactId?: string,
  isAnnualPrice?: boolean
) => {
  const creditCardPayment = {
    name_on_card: creditCardDetail.nameOnCard,
    card_token: creditCardDetail.number,
    last_four_digits: creditCardDetail.maskedNumber.slice(-4),
    type: creditCardDetail.cardType,
    expiry_month: creditCardDetail.expiryMonth,
    expiry_year: creditCardDetail.expiryYear
  };

  return {
    account: {
      id: accountId
    },
    contact: {
      id: contactId
    },
    payment_schedule: isAnnualPrice ? 'Annual' : 'Monthly',
    payment_method: PAYMENT_METHOD.CREDIT,
    credit_card: creditCardPayment
  };
};

const buildPayloadDirectDebit = (
  directDebitDetail: DirectDebitDetail,
  accountId?: string,
  contactId?: string,
  isAnnualPrice?: boolean
) => {
  const {
    accountName,
    bank,
    branch,
    account,
    suffix,
    bsb,
    accountNumber
  } = directDebitDetail;

  let bankCode = bsb;
  if (bank && branch) {
    bankCode = bank + branch;
  }

  let bankNumber = accountNumber;
  if (account && suffix) {
    bankNumber = account + suffix;
  }

  return {
    account: {
      id: accountId
    },
    contact: {
      id: contactId
    },
    payment_schedule: isAnnualPrice ? 'Annual' : 'Monthly',
    payment_method: PAYMENT_METHOD.DEBIT,
    direct_debit: {
      bsb: bankCode,
      bank_name: accountName,
      account_number: bankNumber
    }
  };
};

export const usePaymentProfileCreation = ({
  accountId,
  contactId,
  paymentMethod,
  creditCardDetail,
  directDebitDetail,
  isAnnualPrice = false
}: {
  accountId?: string,
  contactId?: string,
  paymentMethod: string,
  creditCardDetail?: CreditCardDetail,
  directDebitDetail?: DirectDebitDetail,
  isAnnualPrice?: boolean
}): PaymentProfileCreation => {
  const { accessToken } = useGetAccessToken();
  const paymentProfilePayload = paymentMethod === PAYMENT_METHOD.CREDIT ?
    buildPayloadCreditCard(creditCardDetail as CreditCardDetail, accountId, contactId, isAnnualPrice) :
    buildPayloadDirectDebit(directDebitDetail as DirectDebitDetail, accountId, contactId, isAnnualPrice);

  const options = {
    headers: {
      ...REQUEST_HEADERS,
      Authorization: `Bearer ${accessToken}`
    }
  };

  const {
    isLoading: isPaymentProfileLoading, data, isError, isSuccess, refetch
  } = useQuery(
    'payment-profile',
    () =>
      axios.post<PaymentProfile>(
        config.API_PAYMENT_PROFILE_ENDPOINT,
        qs.stringify(paymentProfilePayload),
        options
      ),
    { enabled: false, retry: false }
  );

  const isLoading = isPaymentProfileLoading;
  const paymentProfile = data?.data;
  return {
    isLoading, paymentProfile, isError, isSuccess, refetch
  };
};
