import React, {
  createContext, Dispatch, ReactChild, SetStateAction, useContext, useEffect, useMemo, useState
} from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import qs from 'qs';
import { camelCase, get, mapKeys } from 'lodash';
import {
  PAYMENT_METHOD,
  PHONE_NUMBERS,
  REGIONS,
  STEP_PATH,
  SUBSCRIPTION_TYPE
} from '../../constants';

export type PhoneNumbers = {
  salesPhoneNumber: string;
  customerServicePhoneNumber: string;
  supportPhoneNumber: string;
}

export type SessionContextType = {
  region: string,
  promoCode: string,
  promotionEndDate: string,
  setPromotionEndDate: setStateStringType,
  promotionTermsLink: string,
  setPromotionTermsLink: setStateStringType,
  productId: string,
  productName: string,
  setProductName: setStateStringType,
  productFreePeriod: number,
  setProductFreePeriod: setStateNumberType,
  phoneNumber: PhoneNumbers,
  checkoutId: string,
  isInitialised: boolean,
  subscriptionType: SUBSCRIPTION_TYPE,
  hasPromoCode: boolean,
  setHasPromoCode: Dispatch<SetStateAction<boolean>>,
  hasPurchaseSubmitted: boolean,
  setHasPurchaseSubmitted: Dispatch<SetStateAction<boolean>>,
  contactArchieRowId: string,
  setContactArchieRowId: Dispatch<SetStateAction<string>>,
  productInstanceId: string,
  setProductInstanceId: Dispatch<SetStateAction<string>>,
  serialNumber: string,
  setSerialNumber: Dispatch<SetStateAction<string>>,
  orderNumber: string,
  setOrderNumber: Dispatch<SetStateAction<string>>,
  isNewUser: boolean,
  setIsNewUser: Dispatch<SetStateAction<boolean>>,
  paymentMethod: PAYMENT_METHOD,
  setPaymentMethod: Dispatch<SetStateAction<PAYMENT_METHOD>>,
  pCustomerId: string,
  setPCustomerId: setPCustomerIdType,
  isAnnualPrice: boolean,
  setIsAnnualPrice: setIsAnnualPriceType
};

type setStateStringType = React.Dispatch<React.SetStateAction<string>>
type setStateSubscriptionType = React.Dispatch<React.SetStateAction<SUBSCRIPTION_TYPE>>
type setStateBooleanType = React.Dispatch<React.SetStateAction<boolean>>
type setStateNumberType = React.Dispatch<React.SetStateAction<number>>
type setPCustomerIdType = React.Dispatch<React.SetStateAction<string>>
type setIsAnnualPriceType = React.Dispatch<React.SetStateAction<boolean>>

export const SessionContext = createContext({} as SessionContextType);

export const useSessionContext = (): SessionContextType => useContext(SessionContext);

export const initialRouteState = (
  history: History,
  setRegion: setStateStringType,
  setPromoCode: setStateStringType,
  setProductId: setStateStringType,
  setIsInitialised: setStateBooleanType,
  setSubscriptionType: setStateSubscriptionType,
  setPCustomerId: setPCustomerIdType,
  setIsAnnualPrice: setIsAnnualPriceType
): void => {
  const { pathname, search } = history.location;
  const queryString = qs.parse(search, { ignoreQueryPrefix: true });

  const splitPathname = pathname.split('/');
  const region = splitPathname[1];
  const hasValidRegion = region && Object.values(REGIONS).includes(region.toUpperCase());
  if (hasValidRegion) {
    // e.g. /au/cart
    setRegion(region.toUpperCase());

    const promoCode = get(queryString, 'pc') as string;
    if (promoCode) {
      setPromoCode(promoCode);
    }

    const productId = get(queryString, 'productId') as string;
    if (productId) {
      setProductId(productId);
    }

    const pCustomerId = get(queryString, 'pcustid') as string;
    if (pCustomerId) {
      setPCustomerId(pCustomerId);
    }

    if (splitPathname.includes('annual')) {
      setIsAnnualPrice(true);
    }

    const requireProductIdPathNames = [`/${region}${STEP_PATH.TRIAL.ROOT}`, `/${region}${STEP_PATH.SUBSCRIBE.ROOT}`];
    if (!productId && requireProductIdPathNames.includes(pathname)) {
      history.push('/error');
    }

    setIsInitialised(true);

    if (pathname.includes(STEP_PATH.SUBSCRIBE.ROOT)) {
      setSubscriptionType(SUBSCRIPTION_TYPE.SUBSCRIBE);
    } else if (pathname.includes(STEP_PATH.NEW_TRIAL.ROOT)) {
      setSubscriptionType(SUBSCRIPTION_TYPE.NEW_TRIAL);
    } else {
      setSubscriptionType(SUBSCRIPTION_TYPE.TRIAL);
    }
  } else {
    setIsInitialised(true);
    history.push('/error');
  }
};

const getPhoneNumbers = (region: string): PhoneNumbers => {
  const phoneNumbersForRegion = get(PHONE_NUMBERS, region);
  return mapKeys(phoneNumbersForRegion, (value, key) => camelCase(key)) as {
    salesPhoneNumber: string;
    customerServicePhoneNumber: string;
    supportPhoneNumber: string;
  };
};

export const SessionProvider: React.FC<{children: ReactChild}> = ({ children }) => {
  const history = useHistory();

  const [region, setRegion] = useState(REGIONS.AUSTRALIA);
  const [promoCode, setPromoCode] = useState('');
  const [promotionEndDate, setPromotionEndDate] = useState('');
  const [promotionTermsLink, setPromotionTermsLink] = useState('');
  const [productId, setProductId] = useState('');
  const [productName, setProductName] = useState('');
  const [productFreePeriod, setProductFreePeriod] = useState(-1);
  const [phoneNumber, setPhoneNumber] = useState(getPhoneNumbers(REGIONS.AUSTRALIA));
  const [isInitialised, setIsInitialised] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState(SUBSCRIPTION_TYPE.TRIAL);
  const [hasPurchaseSubmitted, setHasPurchaseSubmitted] = useState(false);
  const [hasPromoCode, setHasPromoCode] = useState(false);
  const [contactArchieRowId, setContactArchieRowId] = useState('');
  const [productInstanceId, setProductInstanceId] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.CREDIT);
  const [pCustomerId, setPCustomerId] = useState('');
  const [isAnnualPrice, setIsAnnualPrice] = useState(false);

  const [checkoutId] = useState(
    (Date.now() + Math.random()).toString().replace(/\./g, '-')
  );

  useEffect(() => {
    initialRouteState(
      history,
      setRegion,
      setPromoCode,
      setProductId,
      setIsInitialised,
      setSubscriptionType,
      setPCustomerId,
      setIsAnnualPrice
    );
    region && setPhoneNumber(getPhoneNumbers(region));
  }, [history, setRegion, setPromoCode, setProductId, setIsInitialised, region, setSubscriptionType]);
  return (
    <SessionContext.Provider value={useMemo(
() => (
      {
        region,
        promoCode,
        promotionEndDate,
        setPromotionEndDate,
        promotionTermsLink,
        setPromotionTermsLink,
        productId,
        productName,
        setProductName,
        productFreePeriod,
        setProductFreePeriod,
        phoneNumber,
        checkoutId,
        isInitialised,
        subscriptionType,
        hasPurchaseSubmitted,
        setHasPurchaseSubmitted,
        hasPromoCode,
        setHasPromoCode,
        contactArchieRowId,
        setContactArchieRowId,
        productInstanceId,
        setProductInstanceId,
        serialNumber,
        setSerialNumber,
        orderNumber,
        setOrderNumber,
        isNewUser,
        setIsNewUser,
        paymentMethod,
        setPaymentMethod,
        pCustomerId,
        setPCustomerId,
        isAnnualPrice,
        setIsAnnualPrice
      }),
    [
      region,
      promoCode,
      promotionEndDate,
      setPromotionEndDate,
      promotionTermsLink,
      setPromotionTermsLink,
      productId,
      productName,
      setProductName,
      productFreePeriod,
      setProductFreePeriod,
      phoneNumber,
      checkoutId,
      isInitialised,
      subscriptionType,
      hasPurchaseSubmitted,
      setHasPurchaseSubmitted,
      hasPromoCode,
      setHasPromoCode,
      contactArchieRowId,
      setContactArchieRowId,
      productInstanceId,
      setProductInstanceId,
      serialNumber,
      setSerialNumber,
      orderNumber,
      setOrderNumber,
      isNewUser,
      setIsNewUser,
      paymentMethod,
      setPaymentMethod,
      pCustomerId,
      setPCustomerId,
      isAnnualPrice,
      setIsAnnualPrice
    ]
    )}
    >
      {children}
    </SessionContext.Provider>
  );
};
