import axios from 'helpers/axios/axios';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import config from 'Config';
import { useQuery } from 'react-query';
import { get } from 'lodash';
import qs from 'qs';
import { useGetAccessToken } from 'hooks/useGetAcessToken/useGetAccessToken';
import { LEGAL_ENTITIES, REQUEST_HEADERS } from '../../constants';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';

type AccountDetails = {
  id: string;
  name: string;
  legal_entity: string;
  business_number: string;
  division: string;
  ea_business_id: string;
  primary_contact:{
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    identity_id: string;
  }
}

type AccountResult = {
  isLoading: boolean;
  isError: boolean;
  accountDetails: AccountDetails | undefined
}

export const useAccountCreation = (contactId:string | undefined): AccountResult => {
  const { isLoading: isAuth0Loading, appState } = useAuth0();
  const { region } = useSessionContext();
  const businessName = get(appState, 'clientInformation.businessName');
  const { accessToken } = useGetAccessToken();
  const accountsRequest = {
    name: businessName,
    legal_entity: get(LEGAL_ENTITIES, region).LEGAL_ENTITY,
    division: 'Business Division',
    ea_business_id: '',
    primary_contact: {
      id: contactId
    }
  };

  const options = {
    headers: {
      ...REQUEST_HEADERS,
      Authorization: `Bearer ${accessToken}`
    }
  };

  const {
    isLoading: isAccountsLoading, data, isError
  } =
    useQuery(
      'accounts',
      () =>
        axios.post<AccountDetails>(
          config.API_ACCOUNTS_ENDPOINT,
          qs.stringify(accountsRequest),
          options
        ),
      { enabled: !!appState && !!contactId && !!accessToken, retry: false }
    );

  const isLoading = isAuth0Loading || isAccountsLoading;
  const accountDetails = data?.data;
  return { isLoading, accountDetails, isError };
};
